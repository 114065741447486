.work__container {
  grid-template-columns: repeat(3, max-content);
  gap: 3rem;
  justify-content: center;
}

.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.work__item {
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  text-transform: capitalize;
  cursor: pointer;
}

.work__item:hover {
  color: var(--container-color);
  background-color: var(--title-color);
  border-radius: 0.5rem;
}

.work__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.work__img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
  filter: grayscale(100%);
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.work__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  cursor: pointer;
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

/* active work */
.active-work {
  background-color: var(--title-color);
  color: var(--container-color);
  border-radius: 0.5rem;
}

.work__modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.work__modal-content {
  width: 500px;
  position: relative;
  background-color: var(--container-color);
  padding: 4.5rem 2.5rem 2.5rem;
  border-radius: 1.5rem;
}

.work__modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.work__modal-title,
.work__modal-description {
  text-align: center;
}

.work__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
}

.work__modal-description {
  padding: 0.3rem;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-2);
}

.work__modal-img {
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

/* Active modal */
.active-modal {
  opacity: 1;
  visibility: visible;
}


/* Breakpoints */
/* Large device */
@media screen and (max-width: 992px) {
  .work__container {
    gap: 1.25rem;
  }

  .work__card {
    padding: 1rem;
  }

  .work__img {
    margin-bottom: 0.75rem;
  }

  .work__title {
    margin-bottom: 0.25rem;
  }
}

/* Medium device */
@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .work__container {
    grid-template-columns: 1fr;
  }

  .work__img {
    width: 100%;
  }

  .work__modal-content {
    padding: 4.5rem 1.5rem 2.5rem;
  }

  .work__modal-description {
    padding: 0;
  }
}

/* Small device */
@media screen and (max-width: 350px) {
  .work__item {
    font-size: var(--small-font-size);
  }

  .work__filters {
    column-gap: 0.25rem;
  }
}